<template>
  <div class="wrapper">
    <div class="wrapper__card">
      <div class="wrapper__card__header">
        <v-form ref="headerForm">
          <div class="left">
            <div class="formDiv">
              <z-input
                v-model="choosenName"
                label="Nome da configuração"
                type="text"
                :hideDetails="true"
                :rules="[(v) => !!v || 'Campo obrigatório']"
              />
            </div>
            <div>
              <z-autocomplete
                v-model="filterValue"
                label="Audiência/filtro"
                :items="filterType"
                itemText="name"
                itemValue="value"
                type="text"
                :hideDetails="true"
                :rules="[(v) => !!v || 'Campo obrigatório']"
              />
            </div>
            <div>
              <v-btn
                depressed
                rounded
                small
                color="#1976D2"
                :loading="impactedLoading"
                @click="
                  (currentPageOfTable = 1),
                    (currentTableSort = ''),
                    getImpactsData(),
                    getDataToTable()
                "
              >
                <v-icon color="white" small>$refresh_rounded</v-icon>
              </v-btn>

              <small style="font-size: 0.75rem">
                Usuários Únicos:<br />
                <span style="font-weight: bold">
                  {{ getFormatedNumber(impactedCount) }}
                </span>
              </small>
              <small style="font-size: 0.75rem">
                Emails Válidos:<br />
                <span style="font-weight: bold">
                  {{ getFormatedNumber(validEmails) }}
                </span>
              </small>
              <small style="font-size: 0.75rem">
                Telefones Válidos:<br />
                <span style="font-weight: bold">
                  {{ getFormatedNumber(validPhones) }}
                </span>
              </small>
            </div>
          </div>
        </v-form>

        <div class="right">
          <div>
            <z-btn
              v-if="actualTab == 'datas'"
              class="mr-2"
              small
              rounded
              primary
              text=""
              :icon="`mdi-cloud-download`"
              :is-loading="loadingDownload"
              @click="openExportModal = true"
            />
            <z-btn
              small
              rounded
              primary
              text="Adicionar"
              :is-loading="loadingAddAudience"
              @click="addData"
            />
          </div>
        </div>
      </div>
      <div class="wrapper__card__body">
        <z-tabs android :tabs="tabs" @change="actualizeTab">
          <template #config>
            <div class="wrapper__card__body__content">
              <div v-show="isSectionOpen" class="wrapper__card__body__content__left">
                <div class="wrapper__card__body__content__left__columns">
                  <span class="title__items">Conexões</span>

                  <div class="mt-3 mb-4">
                    <z-autocomplete
                      label="Selecione uma conexão"
                      v-model="choosenConnection"
                      :items="dbConfigList"
                      itemText="name"
                      placeholder="Pesquisar"
                      type="text"
                      @change="resetDataGroups()"
                      :hideDetails="true"
                      returnObject
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                    />
                  </div>

                  <div
                    v-if="dbConfigTransformedList.length"
                    class="wrapper__card__body__content__left__columns__list mt-3"
                  >
                    <ColumnItem
                      v-for="(item, i) in dbConfigTransformedList"
                      :key="i"
                      :info="item"
                      class="item"
                      @startDraggin="startDrag"
                    />
                  </div>

                  <span class="title__items" v-if="choosenConnection"> Template </span>

                  <div class="wrapper__card__body__content__left__columns__list mt-3">
                    <ColumnItem
                      v-for="(item, i) in templateActionsList"
                      :key="i"
                      :info="item"
                      class="item"
                      @startDraggin="startDrag"
                    />
                  </div>

                  <span class="title__items" v-if="choosenConnection"> Tag </span>

                  <div class="wrapper__card__body__content__left__columns__list mt-3">
                    <ColumnItem
                      v-for="(item, i) in tagActionsList"
                      :key="i"
                      :info="item"
                      class="item"
                      @startDraggin="startDrag"
                    />
                  </div>
                </div>
              </div>

              <div class="wrapper__card__body__content__middle hide">
                <div class="btn mt-6" @click="isSectionOpen = !isSectionOpen">
                  <v-icon color="white" style="z-index: 2 !important">
                    {{
                      isSectionOpen ? "$chevron_left_rounded" : "$chevron_right_rounded"
                    }}
                  </v-icon>
                </div>
                <div class="line"></div>
              </div>

              <div
                v-show="showBlocksInformations"
                class="wrapper__card__body__content__right pb-7"
              >
                <BlockList
                  :info="elementsData"
                  :send-data-prop="getDatasInfo"
                  :send-data-impact-prop="getDatasImpactInfos"
                  :send-data-to-table-prop="getDatasToTableImpactInfos"
                  @formContent="submitFormQuery"
                  @formContentImpact="getImpactCount"
                  @formContentTable="getDataToDynamicTable"
                  @loadingLargeData="isLoadingLargeDataOnLine"
                />
              </div>

              <div
                v-show="!showBlocksInformations && isSectionOpen"
                class="wrapper no-data wrapper__card__body__content__right"
              >
                <div class="wrapper__card">
                  <z-loading />
                </div>
              </div>
            </div>
          </template>
          <template #datas>
            <DynamicTable
              :info-prop="dynamicAudienceDatasToTable"
              :is-loading-prop="tableInfoLoading"
              :choosen-connection-id-prop="choosenConnection?.id || ''"
              @getNewPage="getNewPageForTable"
              @sortTable="getDatasSortedForTable"
            />
          </template>
        </z-tabs>
      </div>
    </div>
    <v-dialog v-model="openExportModal" width="800">
      <div class="modal" style="z-index: 9999 !important">
        <div class="modal__header">
          <div class="modal__header__title">
            <span>Escolher colunas para exportacao </span>
            <br />
          </div>
          <div class="action">
            <v-icon @click="openExportModal = false">mdi-close</v-icon>
          </div>
        </div>

        <div class="modal__body">
          <div class="variableTableDiv">
            <z-autocomplete
              v-model="choosenColumns"
              :items="dataColumns"
              multiple
              placeholder=""
              type="text"
              :hideDetails="true"
            />
          </div>

          <div class="modal--actions mt-3">
            <z-btn text="Ok" primary rounded @click="downloadCsv()" />
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { computed } from "vue";
import { columnsMock, treeElementsMock, elementsGroups } from "./mock";
import ApiClient from "@/services/ApiClient";
import BlockList from "./components/blockList.vue";
import ColumnItem from "./components/columnItem.vue";
import DynamicTable from "./components/dynamicTable.vue";

export default {
  components: {
    ColumnItem,
    BlockList,
    DynamicTable,
  },
  data() {
    return {
      isSectionOpen: true,
      loadingDownload: false,
      showBlocksInformations: true,
      getDatasToTableImpactInfos: false,
      currentTableSort: "",
      currentTableOrder: "",
      currentPageOfTable: 1,
      openExportModal: false,
      choosenColumns: [],
      tableInfoLoading: false,
      tabs: [
        { id: "config", text: "Configurar" },
        { id: "datas", text: "Ver dados" },
      ],
      filterValue: "",
      filterType: [
        {
          name: "Audiência",
          value: "audience",
        },
        {
          name: "Filtro",
          value: "filter",
        },
      ],

      tagInteractionTypes: [
        {
          name: "Possui",
          value: "has",
        },
        {
          name: "Não possui",
          value: "hasnot",
        },
      ],

      interactionTypes: [
        {
          name: "Abriu",
          value: "open",
        },
        {
          name: "Clicou",
          value: "click",
        },
        {
          name: "Não abriu",
          value: "notopen",
        },
        {
          name: "Não clicou",
          value: "notclick",
        },
      ],
      choosenConnection: null,
      loadingAddAudience: false,
      choosenName: "",
      isMountedData: false,
      getDatasInfo: false,
      isMountData: false,
      http: new ApiClient(),
      columnsMock,
      treeElementsMock,
      elementsGroups,
      elementsData: {
        groups: [
          {
            auxId: "",
            conditions: [],
            relation: null,
          },
        ],
      },
      query: null,
      dragging: true,
      test: [],
      dbConfigList: [],
      dbConfigResponseList: [],
      dbConfigTransformedList: [],
      templateActionsList: [],
      tagActionsList: [],
      impactedCount: 0,
      validEmails: 0,
      validPhones: 0,
      impactedLoading: false,
      getDatasImpactInfos: false,
      dynamicAudienceDatasToTable: null,
      actualTab: "",
      templates: [],
      choosenTemplate: null,
      dataColumns: [],
      audiences: [],
      choosenAudience: null,
      interactionType: null,
    };
  },
  watch: {
    choosenConnection(nv) {
      if (nv) {
        this.getConnectionsList();

        // SE TROCAR A CONEXÃO, DEVE RESETAR OS BLOCOS CONFORME REUNIÃO DO DIA 13-04
        this.resetDataGroups();
        this.elementsData.groups[0].auxId = this.generateRandomId();
      }
    },
  },
  created() {
    this.getPrimaryDataConfigList();
    this.elementsData.groups[0].auxId = this.generateRandomId();
    const INFO = this.$store.getters["app/$currentAudienceInfo"];

    if (INFO) this.filterValue = INFO.filterType;
  },
  methods: {
    async changeSelectedTemplate() {
      this.choosenConnection = this.dbConfigList.find(
        (x) => x.id === this.choosenTemplate.databaseConfigId
      );
    },

    async listAudiences() {
      this.audiences = await this.http.get("audience/list");
    },
    async downloadCsv() {
      if (!this.choosenColumns || this.choosenColumns.length === 0) {
        this.$toast.warning("Selecione ao menos uma coluna!");
        return;
      }

      this.loadingDownload = true;
      const OBJ = {};
      OBJ.databaseConfigId = this.choosenConnection.id;
      OBJ.name = this.choosenName;
      OBJ.fields = this.elementsData.groups;
      OBJ.columns = this.choosenColumns;
      try {
        const response = await this.http.post("audienceData/export", OBJ);

        const anchor = document.createElement("a");
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(response);
        anchor.target = "_blank";
        anchor.download = "audience.csv";
        anchor.click();
        this.loadingDownload = false;
      } catch (error) {
        console.log("1");
        console.log(error);
        this.loadingDownload = false;
      }
    },
    actualizeTab(tab) {
      this.actualTab = tab;
    },
    isLoadingLargeDataOnLine(isLoading) {
      if (isLoading) this.showBlocksInformations = false;
      else this.showBlocksInformations = true;
      // {
      //   setTimeout(() =>{

      //   }, 4000)
      // }
      // isLoading ? this.showBlocksInformations = false : this.showBlocksInformations = true
    },
    getNewPageForTable(newPage) {
      this.currentPageOfTable = newPage;
      this.getDataToTable();
    },
    getDatasSortedForTable(name, order) {
      this.currentTableSort = name;
      this.currentTableOrder = order;
      this.getDataToTable();
    },
    async getDataToDynamicTable(form) {
      if (this.choosenConnection) {
        const URL = "audienceData/getAudienceData";

        const OBJ = {};
        OBJ.databaseConfigId = this.choosenConnection?.id;
        OBJ.name = this.choosenName;
        OBJ.fields = form.groups;
        OBJ.pageSize = 20;
        OBJ.page = this.currentPageOfTable;
        (OBJ.sort = this.currentTableSort), (OBJ.order = this.currentTableOrder);

        let invalidRelation,
          invalidValue = false;

        if (OBJ.fields.filter((x) => !x.relation).length > 1) invalidRelation = true;

        OBJ.fields.forEach((element) => {
          if (element.conditions.filter((x) => !x.condictionRelation).length > 1) {
            invalidRelation = true;
            return;
          }

          if (
            element.conditions.filter(
              (x) =>
                !x.value &&
                !x.value1 &&
                x.operator !== "not-null" &&
                x.operator != "null" &&
                x.operator != "sent" &&
                x.operator != "opened" &&
                x.operator != "clicked" &&
                x.operator != "received" &&
                x.operator != "bounce" &&
                x.operator != "not-sent" &&
                x.operator != "not-opened" &&
                x.operator != "not-clicked" &&
                x.operator != "not-received" &&
                x.operator != "not-bounce"
            ).length > 0
          ) {
            invalidValue = true;
            return;
          }
        });

        if (invalidRelation) return;

        if (invalidValue) return;

        try {
          this.tableInfoLoading = true;
          const response = await this.http.post(URL, OBJ);
          this.dynamicAudienceDatasToTable = response;

          if (response.data[0]) {
            this.dataColumns = Object.keys(response.data[0]);
          }

          this.tableInfoLoading = false;
        } catch (error) {
          this.tableInfoLoading = false;
        }
      }
    },
    async getImpactCount(form) {
      if (this.choosenConnection) {
        const URL = "audienceData/getAudienceDataCountFromFields";

        const OBJ = {};
        OBJ.databaseConfigId = this.choosenConnection?.id;
        OBJ.name = this.choosenName;
        OBJ.fields = form.groups;
        if (this.choosenTemplate) OBJ.templateId = this.choosenTemplate.id;
        if (this.interactionType) OBJ.interactionType = this.interactionType.value;
        this.impactedLoading = true;

        let invalidRelation,
          invalidValue = false;

        if (OBJ.fields.filter((x) => !x.relation).length > 1) {
          invalidRelation = true;
        }

        OBJ.fields.forEach((element) => {
          if (element.conditions.filter((x) => !x.condictionRelation).length > 1) {
            invalidRelation = true;
            return;
          }

          if (
            element.conditions.filter(
              (x) =>
                !x.value &&
                !x.value1 &&
                !x.value2 &&
                x.operator !== "not-null" &&
                x.operator != "null" &&
                x.operator != "sent" &&
                x.operator != "opened" &&
                x.operator != "clicked" &&
                x.operator != "received" &&
                x.operator != "bounce" &&
                x.operator != "not-sent" &&
                x.operator != "not-opened" &&
                x.operator != "not-clicked" &&
                x.operator != "not-received" &&
                x.operator != "not-bounce"
            ).length > 0
          ) {
            invalidValue = true;
            return;
          }
        });

        if (invalidRelation) {
          this.$toast.warning("Selecione todas as relacoes entre os elementos");
          this.impactedLoading = false;
          return;
        }

        if (invalidValue) {
          this.$toast.warning("Selecione todos os valores das condicoes");
          this.impactedLoading = false;
          return;
        }

        try {
          const response = await this.http.post(URL, OBJ);
          this.impactedCount = response.count;
          this.validEmails = response.validEmails;
          this.validPhones = response.validPhones;

          this.impactedLoading = false;
          this.$toast.success("Dados atualizados!");
        } catch (error) {
          this.$toast.error("Ocorreu um erro no refresh da conexão");
          this.impactedLoading = false;
        }
      } else {
        this.$toast.warning("Selecione uma conexão para realizar o refresh");
      }
    },
    getFormatedNumber(number) {
      const FORMATED = number
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        .replace(/,/g, ".");
      return FORMATED || 0;
    },
    getCountText(count) {
      if (!count) {
        return "0";
      } else if (count > 1000000) {
        return parseInt((count / 1000000) * 100) / 100 + "M";
      } else if (count > 1000) {
        return parseInt((count / 1000) * 100) / 100 + "K";
      } else {
        return count;
      }
    },
    getImpactsData() {
      this.getDatasImpactInfos = true;

      setTimeout(() => {
        this.getDatasImpactInfos = false;
      }, 500);
    },
    getDataToTable() {
      this.getDatasToTableImpactInfos = true;
      console.log("wee");

      setTimeout(() => {
        this.getDatasToTableImpactInfos = false;
      }, 500);
    },
    addData() {
      this.getDatasInfo = true;

      setTimeout(() => {
        this.getDatasInfo = false;
      }, 500);
    },
    createColumnItensForTag() {
      this.tagActionsList = [];
      let obj = {
        name: "Tags",
        children: [],
      };

      obj.children.push({
        name: "tag",
        resp: "",
        auxId: this.generateRandomId(),
        type: "tag",
        configurationType: "tag",
        condiction: "",
        configurationId: this.choosenConnection.id,
      });

      this.tagActionsList = [obj];
    },
    createColumnItensForTemplate() {
      this.templateActionsList = [];
      let obj = {
        name: "Templates",
        children: [],
      };

      if (this.templates.length > 0)
        obj.children.push({
          name: "Qualquer Template",
          condiction: "notopen",
          resp: "",
          auxId: this.generateRandomId(),
          type: "template-email",
          templateType: "email",
          configurationType: "templateInteraciont",
          configurationId: "all",
          value: "all",
        });

      this.templates.forEach((x) => {
        obj.children.push({
          name: x.templateName,
          condiction: "notopen",
          resp: "",
          auxId: this.generateRandomId(),
          type: "template-" + x.templateType,
          templateType: x.templateType,
          configurationType: "templateInteraciont",
          configurationId: x.id,
          value: x.id,
        });
      });

      this.templateActionsList = [obj];
    },
    async resetDataGroups() {
      this.elementsData.groups = [
        {
          auxId: "",
          conditions: [],
          relation: null,
        },
      ];

      // resetar informações de conexões, caso o campo seja limpo
      this.dbConfigTransformedList = [];

      // resetar a lista de templates e template escolhido, caso o campo de conexões seja limpo
      this.templateActionsList = [];
      this.choosenTemplate = null;

      if (this.choosenConnection.id) {
        await this.getTemplatesFromConfigId(this.choosenConnection.id);
        this.createColumnItensForTemplate();
        this.createColumnItensForTag();
      } else this.templates = [];
    },
    async getTemplatesFromConfigId(id) {
      const URL = `dbConfiguration/templates/${id}`;
      const response = await this.http.get(URL);
      this.templates = response;
    },
    async submitFormQuery(form) {
      if (this.$refs.headerForm.validate()) {
        try {
          this.loadingAddAudience = true;
          const URL =
            this.filterValue != "filter"
              ? "audience/addAudience"
              : "audienceFilter/addAudience";
          const OBJ = {};
          OBJ.databaseConfigId = this.choosenConnection.id;
          OBJ.name = this.choosenName;
          OBJ.fields = form.groups;
          if (this.choosenTemplate) OBJ.templateId = this.choosenTemplate.id;
          if (this.interactionType) OBJ.interactionType = this.interactionType.value;
          const response = await this.http.post(URL, OBJ);

          if (response) {
            this.$toast.success("Configuração realizada com sucesso");
            this.loadingAddAudience = false;
            this.resetDataGroups();

            const INFO = this.$store.getters["app/$currentAudienceInfo"];

            if (INFO) {
              INFO.audience = response;
              this.$store.dispatch("app/SET_CURRENT_AUDIENCE_INFO", INFO);
              this.$router.push(INFO.route.path);
            } else this.$router.push({ name: "audienceIndexPage" });
          }
        } catch (error) {
          this.$toast.error("Erro ao salvar dados");
          // console.error(error);
          this.loadingAddAudience = false;
        }
      }
    },
    async getConnectionsList() {
      try {
        this.dbConfigResponseList = [];
        const response = await this.http.get(
          `dbConfiguration/related/${this.choosenConnection.id}`
        );

        this.dbConfigResponseList.push({
          ...response.primaryConfig,
          configurationType: "primaryData",
        });

        if (response?.additionalData.length) {
          for (const i of response.additionalData) {
            this.dbConfigResponseList.push({
              ...i,
              configurationType: "additionalData",
            });
          }
        }
        if (response?.conversionData.length) {
          for (const i of response.conversionData) {
            this.dbConfigResponseList.push({
              ...i,
              configurationType: "conversionData",
            });
          }
        }
        this.transformResponseToRedableForm();
      } catch (error) {
        this.$toast.error("Erro ao buscar configurações");
      }
    },

    async getPrimaryDataConfigList() {
      try {
        const req = await this.http.get(
          "dbConfiguration/list?status=updated&sort=created&order=DESC"
        );
        this.dbConfigList = req;
      } catch (error) {
        this.$toast.error("Erro ao buscar lista de configurações");
      }
    },

    startDrag(evt, item) {
      const id = this.generateRandomId();
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      item.auxId = id;
      evt.dataTransfer.setData("itemID", id);
    },

    transformResponseToRedableForm() {
      this.isMountData = true;
      this.dbConfigTransformedList = [];

      for (const column of this.dbConfigResponseList) {
        let obj = {};

        obj.name = column.name;
        obj.children = [];

        const PARSED_OBJECT = JSON.parse(column.fields);

        for (const [key, value] of Object.entries(PARSED_OBJECT)) {
          obj.children.push({
            name: key,
            condiction: "",
            resp: "",
            auxId: "",
            type: value.type,
            configurationType: column?.configurationType,
            configurationId: column?.id,
          });
        }

        // obj.children.push({
        //   name: "tag",
        //   condiction: "",
        //   resp: "",
        //   auxId: "",
        //   type: "string",
        //   configurationType: 'tag',
        //   configurationId: column?.id,
        // })

        if (column.hasUpdatedAt) {
          obj.children.push({
            name: "updatedAtZoox",
            condiction: "",
            resp: "",
            auxId: "",
            type: "datetime",
            configurationType: column?.configurationType,
            configurationId: column?.id,
          });
        }

        this.dbConfigTransformedList.push(obj);
      }
      this.isMountData = false;
    },

    generateRandomId() {
      const PART_1 = Math.floor(Math.random() * 245);
      const PART_2 = Date.now();
      return `${PART_1}${PART_2}`;
    },
  },
  // provide: {
  //   // columnsMock,
  // },
  provide() {
    // SEMPRE QUE FOR CRIADO UMA NOVA COLUNA E A MESMA TER DADOS PARA PODER ARRASTAR E SOLTAR
    // É NECESSÁRIO PASSAR ESSA LISTA COMO INJEÇÃO DE DEPENDENCIA E SE CONSUMIDA NO COMPONENTE ONDE OS DADOS ESTÃO SENDO SOLTOS (DROP) blockItem.vue
    return {
      dbConfigTransformedList: computed(() => this.dbConfigTransformedList),
      templateActionsList: computed(() => this.templateActionsList),
      tagActionsList: computed(() => this.tagActionsList),
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.variableTableDiv {
  max-height: 60vh;
  height: 100%;
  overflow-y: scroll;
}

.variableTable {
  border-spacing: 0px;
  width: 100%;
}

.variableTable td {
  // border: 1px solid black;
  padding: 5px;
  color: black;
}

.variableTable th {
}

.modal {
  background: #ffffff;
  padding: $z-s-1;
  border-radius: 15px;
  color: $z-black-1;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $z-s-1;

    &__title {
      span {
        font-weight: 600;
        text-transform: uppercase;
        line-height: 15px;
        font-size: 1rem;
      }
    }
  }

  &__body {
    color: #4e4e4e;
    .content {
      strong {
        display: block;
        font-size: 0.85rem;
      }
      &__timer-type {
      }

      &__recurring-info {
        .custom_inpt {
          ::v-deep .v-text-field__slot input {
            text-align: center !important;
          }

          ::v-deep .v-select__selections input {
            text-align: center !important;
            // border: 1px solid red !important;
            padding: 0 !important;
          }

          ::v-deep .v-select__selections .v-select__selection {
            // border: 2px solid green !important;
            text-align: center !important;
            width: 100% !important;
          }
        }
        .repeat-each {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          // flex-wrap: wrap;
          @media (max-width: 650px) {
            flex-wrap: wrap;
          }
        }
      }
    }
    // .schedule {
    //   &__title {
    //     margin-bottom: 0.5rem;
    //     span {
    //       font-size: 1rem;
    //       font-weight: 500;
    //     }
    //   }

    //   &__content {
    //     & > div {
    //       span {
    //         display: block;
    //         font-weight: 400;
    //       }
    //     }

    //     &__repeat,
    //     &__repeat-times {
    //       & > div {
    //         margin-bottom: 0.5rem;
    //       }
    //     }
    //   }
    // }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.formDiv {
  min-width: 250px;
}

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    // overflow: hidden;
    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: 1.2rem;
      flex-wrap: wrap;
      .left {
        // flex: 1 1 0;

        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex-wrap: wrap;
        & > div {
          flex: 1 1 auto;
          width: 250px;
          // width: 0;
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }

        // .loader {
        //   border: 3px solid #f3f3f3;
        //   border-radius: 50%;
        //   border-top: 3px solid #3498db;
        //   // width: 65px;
        //   // height: 25px;
        //   width: 36px !important;
        //   height: 20px !important;
        //   -webkit-animation: spin 2s linear infinite; /* Safari */
        //   animation: spin 2s linear infinite;
        // }
        // @-webkit-keyframes spin {
        //   0% {
        //     -webkit-transform: rotate(0deg);
        //   }
        //   100% {
        //     -webkit-transform: rotate(360deg);
        //   }
        // }

        // @keyframes spin {
        //   0% {
        //     transform: rotate(0deg);
        //   }
        //   100% {
        //     transform: rotate(360deg);
        //   }
        // }
      }
      .right {
        flex: 1 1 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }

      & > div {
        // border: 1px solid;
      }
    }
    &__body {
      margin-bottom: 1.5rem;
      max-height: 90%;
      &__content {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: $z-s-1;
        height: 100% !important;

        & > div {
          padding: 0.5rem 0.8rem;
          height: 100%;
        }
        &__left {
          flex: 1 1 200px;
          &__columns {
            margin-top: 0.5rem;

            .title__items {
              font-weight: 700;
              font-size: 0.95em;
              line-height: 13px;
              letter-spacing: 0.15px;
              text-transform: uppercase;
            }
            &__list {
              padding: 0.3rem $z-s-2;
              max-height: 55vh;
              overflow-y: auto;
              @include trackScrollBar;

              .item {
                &:not(:last-child) {
                  margin-bottom: 0.3rem;
                }
              }

              @media (min-width: 1600px) {
                max-height: 35vh;
              }
            }
          }
        }
        &__middle {
          // border: 1px solid #e4e4e4;
          padding: 0 !important;
          width: 30px !important;
          height: calc(100vh - 128px) !important;
          position: relative;
          z-index: 2;

          .btn {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            z-index: 999 !important;
            i {
              background: #1976d2;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .line {
            z-index: 0 !important;
            border: 1px solid #e4e4e4;
            height: 100%;
            width: 1px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -48%);
          }
        }
        &__right {
          //border: 1px solid red;
          flex: 3 1 400px;
          max-height: 73vh !important;
          overflow-y: auto;
          @include trackScrollBar;

          display: flex;
          flex-direction: column;
          gap: 0.7rem;

          @media (min-width: 1580px) {
            max-height: 80vh;
          }
        }
        .no-content {
          min-height: 60vh;
        }

        @media (max-width: 737px) {
          .hide {
            display: none;
          }
        }
      }
    }

    &--actions {
      // border: 1px solid red;
      margin-top: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
