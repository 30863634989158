var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("div", { staticClass: "wrapper__card" }, [
        _c(
          "div",
          { staticClass: "wrapper__card__header" },
          [
            _c("v-form", { ref: "headerForm" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "div",
                  { staticClass: "formDiv" },
                  [
                    _c("z-input", {
                      attrs: {
                        label: "Nome da configuração",
                        type: "text",
                        hideDetails: true,
                        rules: [(v) => !!v || "Campo obrigatório"],
                      },
                      model: {
                        value: _vm.choosenName,
                        callback: function ($$v) {
                          _vm.choosenName = $$v
                        },
                        expression: "choosenName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("z-autocomplete", {
                      attrs: {
                        label: "Audiência/filtro",
                        items: _vm.filterType,
                        itemText: "name",
                        itemValue: "value",
                        type: "text",
                        hideDetails: true,
                        rules: [(v) => !!v || "Campo obrigatório"],
                      },
                      model: {
                        value: _vm.filterValue,
                        callback: function ($$v) {
                          _vm.filterValue = $$v
                        },
                        expression: "filterValue",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          depressed: "",
                          rounded: "",
                          small: "",
                          color: "#1976D2",
                          loading: _vm.impactedLoading,
                        },
                        on: {
                          click: function ($event) {
                            ;(_vm.currentPageOfTable = 1),
                              (_vm.currentTableSort = ""),
                              _vm.getImpactsData(),
                              _vm.getDataToTable()
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { color: "white", small: "" } }, [
                          _vm._v("$refresh_rounded"),
                        ]),
                      ],
                      1
                    ),
                    _c("small", { staticStyle: { "font-size": "0.75rem" } }, [
                      _vm._v(" Usuários Únicos:"),
                      _c("br"),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getFormatedNumber(_vm.impactedCount)) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("small", { staticStyle: { "font-size": "0.75rem" } }, [
                      _vm._v(" Emails Válidos:"),
                      _c("br"),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getFormatedNumber(_vm.validEmails)) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("small", { staticStyle: { "font-size": "0.75rem" } }, [
                      _vm._v(" Telefones Válidos:"),
                      _c("br"),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getFormatedNumber(_vm.validPhones)) +
                            " "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "right" }, [
              _c(
                "div",
                [
                  _vm.actualTab == "datas"
                    ? _c("z-btn", {
                        staticClass: "mr-2",
                        attrs: {
                          small: "",
                          rounded: "",
                          primary: "",
                          text: "",
                          icon: `mdi-cloud-download`,
                          "is-loading": _vm.loadingDownload,
                        },
                        on: {
                          click: function ($event) {
                            _vm.openExportModal = true
                          },
                        },
                      })
                    : _vm._e(),
                  _c("z-btn", {
                    attrs: {
                      small: "",
                      rounded: "",
                      primary: "",
                      text: "Adicionar",
                      "is-loading": _vm.loadingAddAudience,
                    },
                    on: { click: _vm.addData },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "wrapper__card__body" },
          [
            _c("z-tabs", {
              attrs: { android: "", tabs: _vm.tabs },
              on: { change: _vm.actualizeTab },
              scopedSlots: _vm._u([
                {
                  key: "config",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "wrapper__card__body__content" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isSectionOpen,
                                  expression: "isSectionOpen",
                                },
                              ],
                              staticClass: "wrapper__card__body__content__left",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "wrapper__card__body__content__left__columns",
                                },
                                [
                                  _c("span", { staticClass: "title__items" }, [
                                    _vm._v("Conexões"),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "mt-3 mb-4" },
                                    [
                                      _c("z-autocomplete", {
                                        attrs: {
                                          label: "Selecione uma conexão",
                                          items: _vm.dbConfigList,
                                          itemText: "name",
                                          placeholder: "Pesquisar",
                                          type: "text",
                                          hideDetails: true,
                                          returnObject: "",
                                          rules: [
                                            (v) => !!v || "Campo obrigatório",
                                          ],
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.resetDataGroups()
                                          },
                                        },
                                        model: {
                                          value: _vm.choosenConnection,
                                          callback: function ($$v) {
                                            _vm.choosenConnection = $$v
                                          },
                                          expression: "choosenConnection",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.dbConfigTransformedList.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "wrapper__card__body__content__left__columns__list mt-3",
                                        },
                                        _vm._l(
                                          _vm.dbConfigTransformedList,
                                          function (item, i) {
                                            return _c("ColumnItem", {
                                              key: i,
                                              staticClass: "item",
                                              attrs: { info: item },
                                              on: {
                                                startDraggin: _vm.startDrag,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.choosenConnection
                                    ? _c(
                                        "span",
                                        { staticClass: "title__items" },
                                        [_vm._v(" Template ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "wrapper__card__body__content__left__columns__list mt-3",
                                    },
                                    _vm._l(
                                      _vm.templateActionsList,
                                      function (item, i) {
                                        return _c("ColumnItem", {
                                          key: i,
                                          staticClass: "item",
                                          attrs: { info: item },
                                          on: { startDraggin: _vm.startDrag },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _vm.choosenConnection
                                    ? _c(
                                        "span",
                                        { staticClass: "title__items" },
                                        [_vm._v(" Tag ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "wrapper__card__body__content__left__columns__list mt-3",
                                    },
                                    _vm._l(
                                      _vm.tagActionsList,
                                      function (item, i) {
                                        return _c("ColumnItem", {
                                          key: i,
                                          staticClass: "item",
                                          attrs: { info: item },
                                          on: { startDraggin: _vm.startDrag },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "wrapper__card__body__content__middle hide",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "btn mt-6",
                                  on: {
                                    click: function ($event) {
                                      _vm.isSectionOpen = !_vm.isSectionOpen
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticStyle: {
                                        "z-index": "2 !important",
                                      },
                                      attrs: { color: "white" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isSectionOpen
                                              ? "$chevron_left_rounded"
                                              : "$chevron_right_rounded"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "line" }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showBlocksInformations,
                                  expression: "showBlocksInformations",
                                },
                              ],
                              staticClass:
                                "wrapper__card__body__content__right pb-7",
                            },
                            [
                              _c("BlockList", {
                                attrs: {
                                  info: _vm.elementsData,
                                  "send-data-prop": _vm.getDatasInfo,
                                  "send-data-impact-prop":
                                    _vm.getDatasImpactInfos,
                                  "send-data-to-table-prop":
                                    _vm.getDatasToTableImpactInfos,
                                },
                                on: {
                                  formContent: _vm.submitFormQuery,
                                  formContentImpact: _vm.getImpactCount,
                                  formContentTable: _vm.getDataToDynamicTable,
                                  loadingLargeData:
                                    _vm.isLoadingLargeDataOnLine,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.showBlocksInformations &&
                                    _vm.isSectionOpen,
                                  expression:
                                    "!showBlocksInformations && isSectionOpen",
                                },
                              ],
                              staticClass:
                                "wrapper no-data wrapper__card__body__content__right",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "wrapper__card" },
                                [_c("z-loading")],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "datas",
                  fn: function () {
                    return [
                      _c("DynamicTable", {
                        attrs: {
                          "info-prop": _vm.dynamicAudienceDatasToTable,
                          "is-loading-prop": _vm.tableInfoLoading,
                          "choosen-connection-id-prop":
                            _vm.choosenConnection?.id || "",
                        },
                        on: {
                          getNewPage: _vm.getNewPageForTable,
                          sortTable: _vm.getDatasSortedForTable,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          model: {
            value: _vm.openExportModal,
            callback: function ($$v) {
              _vm.openExportModal = $$v
            },
            expression: "openExportModal",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal",
              staticStyle: { "z-index": "9999 !important" },
            },
            [
              _c("div", { staticClass: "modal__header" }, [
                _c("div", { staticClass: "modal__header__title" }, [
                  _c("span", [_vm._v("Escolher colunas para exportacao ")]),
                  _c("br"),
                ]),
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "v-icon",
                      {
                        on: {
                          click: function ($event) {
                            _vm.openExportModal = false
                          },
                        },
                      },
                      [_vm._v("mdi-close")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "modal__body" }, [
                _c(
                  "div",
                  { staticClass: "variableTableDiv" },
                  [
                    _c("z-autocomplete", {
                      attrs: {
                        items: _vm.dataColumns,
                        multiple: "",
                        placeholder: "",
                        type: "text",
                        hideDetails: true,
                      },
                      model: {
                        value: _vm.choosenColumns,
                        callback: function ($$v) {
                          _vm.choosenColumns = $$v
                        },
                        expression: "choosenColumns",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "modal--actions mt-3" },
                  [
                    _c("z-btn", {
                      attrs: { text: "Ok", primary: "", rounded: "" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadCsv()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }